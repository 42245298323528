import React from 'react';
import {graphql, StaticQuery} from 'gatsby';

import Graphic from 'components/Graphic.js';
import Hero from 'components/Hero.js';
import Layout from 'components/Layout.js';
import Meta from 'components/Meta.js';
import Tile from 'components/Tile.js';
import i18n from 'data/i18n.en.json';

export default class LeadershipTeam extends React.Component {
    renderModalContent(node) {
        return (
            <>
                <img alt="" className="photo" src={node.acf.display_picture} />
                <h3>{node.title}</h3>
                <a href={node.acf.linkedin} title={i18n.social.linkedInVisit}>
                    <Graphic name="iconSocialLinkedIn" />
                    {i18n.social.linkedInProfile}
                </a>
                <article
                    dangerouslySetInnerHTML={{
                        __html: node.content,
                    }}
                />
            </>
        );
    }

    renderMemberTiles(members) {
        return (
            <section className="tiles">
                {members.map(({node}) => (
                    <Tile
                        copy={node.acf.job_title}
                        image={node.acf.display_picture}
                        key={node.wordpress_id}
                        isStatic
                        title={node.title}
                    />
                ))}
            </section>
        );
    }

    render() {
        return (
            <StaticQuery
                query={graphql`
                    fragment TeamData on wordpress__wp_team {
                        acf {
                            board_member
                            display_picture
                            job_title
                            linkedin
                        }
                        content
                        title
                        wordpress_id
                    }
                    {
                        pageDetails: wordpressPage(wordpress_id: {eq: 105}) {
                            acf {
                                subtitle
                            }
                            title
                        }
                        BoardMembers: allWordpressWpTeam(
                            filter: {acf: {board_member: {eq: true}}}
                            sort: {fields: date}
                        ) {
                            edges {
                                node {
                                    ...TeamData
                                }
                            }
                        }
                        TeamMembers: allWordpressWpTeam(
                            filter: {acf: {board_member: {eq: false}}}
                            sort: {fields: date}
                        ) {
                            edges {
                                node {
                                    ...TeamData
                                }
                            }
                        }
                        blogLink: wordpressCategory(
                            wordpress_id: {eq: 106132}
                        ) {
                            path
                        }
                    }
                `}
                render={(data) => (
                    <Layout className="leadership contain">
                        <Meta
                            description={data.pageDetails.acf.subtitle}
                            title={data.pageDetails.title}
                        />
                        <Hero
                            subtitle={data.pageDetails.acf.subtitle}
                            title={data.pageDetails.title}
                        />
                        <nav className="tiles">
                            <Tile
                                copy={i18n.aboutTiles.aboutUs}
                                icon="iconAbout"
                                title={i18n.aboutTiles.aboutUsTitle}
                                to="/company/about"
                            />
                            <Tile
                                copy={i18n.aboutTiles.careers}
                                icon="iconCareers"
                                title={i18n.aboutTiles.careersTitle}
                                to="/company/careers/"
                            />
                            <Tile
                                copy={i18n.aboutTiles.blog}
                                icon="iconBlog"
                                title={i18n.aboutTiles.blogTitle}
                                to={data.blogLink.path}
                            />
                        </nav>
                        <h3>{i18n.leadership.boardMembers}</h3>
                        {this.renderMemberTiles(data.BoardMembers.edges)}
                        <h3>{i18n.leadership.leadershipTeam}</h3>
                        {this.renderMemberTiles(data.TeamMembers.edges)}
                    </Layout>
                )}
            />
        );
    }
}
